import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  ChevronLeft,
  ChevronRight,
  Users,
  GraduationCap,
  UserCircle,
  LoaderCircle,
} from "lucide-react";
import axios from "axios";

const steps = [
  {
    title: "What's your role?",
    icon: <Users className="w-8 h-8 text-blue-600" />,
    description: "We have tools to save time for every role. Help us help you!",
    field: "role",
    options: ["Teacher", "Professor", "Teaching Assistant", "Administrator"],
  },
  {
    title: "What grade level(s) do you serve?",
    icon: <GraduationCap className="w-8 h-8 text-blue-600" />,
    description:
      "We offer age-appropriate tools for all levels. Tell us where to focus for you.",
    field: "gradeLevels",
    options: [
      "Higher Education (College & University)",
      "High School (Grades 9 - 12)",
      "Middle School (Grades 7 - 8)",
      "Upper Elementary (Grades 4 - 6)",
      "Lower Elementary (Grades Pre-K - 3)",
      "Professional Level Learning",
    ],
  },
  {
    title: "How do you wish to be addressed?",
    icon: <UserCircle className="w-8 h-8 text-blue-600" />,
    description:
      "You're almost done! Help us customize engagement with your students.",
    field: "name",
    inputs: [
      { label: "Full Name", placeholder: "Enter your full name" },
      {
        label: "Name Students Call You",
        placeholder: "E.g., Mr. Smith, Ms. Johnson",
      },
    ],
  },
];

export default function UserPersona({ setSignUpData, signUpData }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    role: "",
    gradeLevels: [],
    name: { fullName: "", studentName: "" },
  });
  const [loader, setLoader] = useState(false);
  useEffect(() => {}, []);

  const loginFn = async () => {
    try {
      setLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/login-user`,
        {
          email: signUpData.signUpemail,
          tokenid: localStorage.getItem("user-token"),
          role: formData.role,
          grade_level: formData.gradeLevels,
          wish_addressed_full_name: formData.name.fullName,
          name_student_call_you: formData.name.studentName,
          profession_type: "teacher",
        }
      );
      setLoader(false);
      if (response.data.status) {
        console.log("response", response.data.data.token);
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("user-type", "teacher");
        window.location.href = "/payment";
      }
    } catch (err) {
      setLoader(false);
    }
  };

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      console.log("Form submitted:", formData);
      loginFn();
      // Here you would typically send the data to your backend
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]:
        field === "gradeLevels"
          ? prevData.gradeLevels.includes(value)
            ? prevData.gradeLevels.filter((level) => level !== value)
            : [...prevData.gradeLevels, value]
          : value,
    }));
  };

  const handleNameInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      name: { ...prevData.name, [field]: value },
    }));
  };

  const isStepValid = () => {
    const currentField = steps[currentStep].field;
    if (currentField === "role") return formData.role !== "";
    if (currentField === "gradeLevels") return formData.gradeLevels.length > 0;
    if (currentField === "name")
      return formData.name.fullName !== "" && formData.name.studentName !== "";
    if (loader) return true;
    return false;
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-600 to-blue-800 flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-xl shadow-2xl overflow-hidden w-full max-w-2xl"
      >
        <div className="p-8">
          <div className="flex items-center justify-center mb-6">
            <svg
              className="w-12 h-12 text-blue-600"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" rx="4" fill="currentColor" />
              <path
                d="M17 16C17 17.1046 16.1046 18 15 18H9C7.89543 18 7 17.1046 7 16V8C7 6.89543 7.89543 6 9 6H15C16.1046 6 17 6.89543 17 8V16Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17 11L14.5 8.5L9 14L7 12"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 6V9.5"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17 12.5V16"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <AnimatePresence mode="wait">
            <motion.div
              key={currentStep}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.3 }}
            >
              <h2 className="text-2xl font-bold text-center text-gray-800 mb-2">
                {steps[currentStep].title}
              </h2>
              <div className="flex justify-center mb-6">
                {steps[currentStep].icon}
              </div>
              <p className="text-center text-gray-600 mb-6">
                {steps[currentStep].description}
              </p>
              {steps[currentStep].options && (
                <div className="space-y-2">
                  {steps[currentStep].options.map((option, index) => (
                    <button
                      key={index}
                      onClick={() =>
                        handleInputChange(steps[currentStep].field, option)
                      }
                      className={`w-full p-3 text-left rounded-md transition-colors duration-200 ${
                        steps[currentStep].field === "gradeLevels"
                          ? formData.gradeLevels.includes(option)
                            ? "bg-blue-100 text-blue-800"
                            : "bg-gray-100 hover:bg-gray-200"
                          : formData[steps[currentStep].field] === option
                          ? "bg-blue-100 text-blue-800"
                          : "bg-gray-100 hover:bg-gray-200"
                      }`}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              )}
              {steps[currentStep].inputs && (
                <div className="space-y-4">
                  {steps[currentStep].inputs.map((input, index) => (
                    <div key={index}>
                      <label
                        htmlFor={input.label}
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        {input.label}
                      </label>
                      <input
                        type="text"
                        id={input.label}
                        placeholder={input.placeholder}
                        value={
                          formData.name[
                            input.label === "Full Name"
                              ? "fullName"
                              : "studentName"
                          ]
                        }
                        onChange={(e) =>
                          handleNameInputChange(
                            input.label === "Full Name"
                              ? "fullName"
                              : "studentName",
                            e.target.value
                          )
                        }
                        className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                  ))}
                </div>
              )}
            </motion.div>
          </AnimatePresence>
          <div className="flex justify-between mt-8">
            <button
              onClick={handlePrevious}
              disabled={currentStep === 0}
              className={`flex items-center ${
                currentStep === 0
                  ? "text-gray-400 cursor-not-allowed"
                  : "text-blue-600 hover:text-blue-800"
              }`}
            >
              <ChevronLeft className="w-5 h-5 mr-1" />
              Back
            </button>
            <button
              onClick={handleNext}
              disabled={!isStepValid()}
              className={`flex items-center ${
                isStepValid()
                  ? "text-blue-600 hover:text-blue-800"
                  : "text-gray-400 cursor-not-allowed"
              }`}
            >
              {loader ? (
                <div className="spinner-container flex justify-center items-center">
                  <LoaderCircle className="spinner" size={25} />
                </div>
              ) : currentStep === steps.length - 1 ? (
                "Finish"
              ) : (
                "Next"
              )}
              {!loader && <ChevronRight className="w-5 h-5 ml-1" />}
            </button>
          </div>
        </div>
        <div className="bg-gray-100 px-8 py-4">
          <div className="flex justify-center space-x-2">
            {steps.map((_, index) => (
              <div
                key={index}
                className={`w-2 h-2 rounded-full ${
                  index === currentStep ? "bg-blue-600" : "bg-gray-300"
                }`}
              />
            ))}
          </div>
        </div>
      </motion.div>
    </div>
  );
}
