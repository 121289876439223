// import React from "react";

// export default function Footer() {
//   return (
//     <footer className="bg-gray-800 text-white py-6">
//       <div className="container mx-auto px-4">
//         <div className="flex justify-between items-center">
//           {/* Left Section */}
//           <div className="text-sm">
//             &copy; {new Date().getFullYear()} ProfPilot. All rights reserved.
//           </div>
//           {/* Right Section */}
//           <div className="flex space-x-4">
//             <a href="#" className="hover:text-gray-400">
//               Privacy Policy
//             </a>
//             <a href="#" className="hover:text-gray-400">
//               Terms of Service
//             </a>
//             {/* <a href="#" className="hover:text-gray-400">
//               Contact Us
//             </a> */}
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// }

import React from "react";
import { Facebook, Twitter, Instagram, Linkedin } from "lucide-react";
import ProfPilotLogo from "./AfterLoginLogo";
import logo from "../assets/ProfPilotLogo2.png";
const Footer = () => {
  return (
    <footer className="bg-blue-700 text-white py-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
        <div className="container mx-auto ">
          <div className="flex flex-col md:flex-row justify-between items-center">
            {/* Logo and Description */}
            <div className="mb-8 md:mb-0">
              {/* <h2 className="text-2xl font-bold"> */}
              <ProfPilotLogo className="h-10" />
              {/* <img src={logo} className="h-10" /> */}
              {/* </h2> */}
              <p className="mt-2 text-white text-center">
                Empowering educators with AI
              </p>
            </div>

            {/* Social Media Links */}
            <div className="flex space-x-6 mb-8 md:mb-0">
              <a
                href="https://www.facebook.com/profile.php?id=61565021673054&mibextid=LQQJ4d"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-blue-400"
              >
                <Facebook size={20} />
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-blue-400"
              >
                <Twitter size={20} />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-blue-400"
              >
                <Instagram size={20} />
              </a>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-blue-400"
              >
                <Linkedin size={20} />
              </a>
            </div>

            {/* Quick Links */}
            <div>
              <ul className="text-center text-white space-y-2">
                <li>
                  <a href="#" className="hover:text-blue-400">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-blue-400">
                    Contact
                  </a>
                </li>
                <li>
                  <a
                    href="https://drive.google.com/file/d/1urzhyO5vzWwY8DAlaP6dPqWvlFlPxgKT/view"
                    target="blank"
                    className="hover:text-blue-600"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    href="https://drive.google.com/file/d/1xI82JEiNX0VnOwXx5vul2l2ZxhNTlNBE/view"
                    target="blank"
                    className="hover:text-blue-400"
                  >
                    Terms of Service
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Footer Bottom */}
          <div className="mt-10 text-center text-white">
            © {new Date().getFullYear()} ProfPilot. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
