import React, { useState } from "react";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ArrowLeft,
  Book,
  Zap,
  Download,
  Save,
  Eye,
  Plus,
  Trash2,
  Wand2,
  ChevronDown,
  ChevronUp,
  CreditCard,
  XCircle,
  Clock,
} from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../@/components//ui/payment-dailog";
import { Link } from "react-router-dom";
import { Button } from "../@/components/ui/button";
import { Input } from "../@/components/ui/input";
import { Textarea } from "../@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../@/components/ui/select";
import { Slider } from "../@/components/ui/slider";
import { Switch } from "../@/components/ui/switch";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../@/components/ui/card";

import Footer from "./Footer";
import axios from "axios";
import AfterLoginLogo from "./DashLogo";
const gradeLevels = [
  "Higher Education (College & University)",
  "High School (Grades 9 - 12)",
  "Middle School (Grades 7 - 8)",
  "Upper Elementary (Grades 4 - 6)",
  "Lower Elementary (Grades Pre-K - 3)",
];
const subjects = [
  "Mathematics",
  "Science",
  "English",
  "History",
  "Geography",
  "Art",
];

export default function Component() {
  const [lessonTitle, setLessonTitle] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [subject, setSubject] = useState("");
  const [topic, setTopic] = useState("");
  const [duration, setDuration] = useState(60);
  const [objectives, setObjectives] = useState([""]);
  const [activities, setActivities] = useState([""]);
  const [materials, setMaterials] = useState([""]);
  const [assessment, setAssessment] = useState("");
  const [differentiation, setDifferentiation] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [includeInteractiveElements, setIncludeInteractiveElements] =
    useState(false);
  const [includeAccessibilityOptions, setIncludeAccessibilityOptions] =
    useState(false);
  const [includeTechnologyIntegration, setIncludeTechnologyIntegration] =
    useState(false);
  const [generatedPlan, setGeneratedPlan] = useState(null);
  const [customGradeLevel, setCustomGradeLevel] = useState("");
  const [customSubject, setCustomSubject] = useState("");
  const [showCustomGradeLevel, setShowCustomGradeLevel] = useState(false);
  const [showCustomSubject, setShowCustomSubject] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [loader4, setLoader4] = useState(false);
  const [error1, setError1] = useState("");
  const [review, setReview] = useState("");
  const [error, setError] = useState({
    error2: "",
    error3: "",
    error4: "",
  });
  const [isOpen, setIsOpen] = useState(false);

  const handleAddItem = (setter, items) => {
    setter([...items, ""]);
  };
  const openModal = () => setIsOpen(true);

  const handleRemoveItem = (setter, items, index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setter(newItems);
  };

  const handleItemChange = (setter, items, index, value) => {
    const newItems = [...items];
    newItems[index] = value;
    setter(newItems);
  };

  const handleGenerate = () => {
    downloadFn();
  };

  const objectGenerating = async () => {
    try {
      setLoader1(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/generate-lesson-objectives`,
        {
          lesson_title: lessonTitle,
          grade_level: showCustomGradeLevel ? customGradeLevel : gradeLevel,
          subject: showCustomSubject ? customSubject : subject,
          topic: topic,
          duration: duration,
          learning_objectives: "",
          activities: "",
          assessment: "",
          differentiation: "",
          moduleType: "InteractiveLesson",
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      setLoader1(false);
      if (response.data.status) {
        const array = Object.values(response.data.data);
        setObjectives(array);
      }
    } catch (error) {
      if (
        error.response.data.message ==
        "You have reached the limit of 7 uses for this module"
      ) {
        openModal();
      } else {
        toast.error(error.response.data.message);
      }
      setLoader1(false);
    }
  };

  const activitiesGenerate = async () => {
    try {
      setLoader2(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/generate-lesson-objectives`,
        {
          lesson_title: lessonTitle,
          grade_level: showCustomGradeLevel ? customGradeLevel : gradeLevel,
          subject: showCustomSubject ? customSubject : subject,
          topic: topic,
          duration: duration,
          learning_objectives: objectives,
          activities: "",
          assessment: "",
          differentiation: "",
          moduleType: "InteractiveLesson",
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      setLoader2(false);
      if (response.data.status) {
        console.log("response", response.data.data);
        const array = Object.values(response.data.data);
        setActivities(array);
      }
    } catch (error) {
      if (
        error.response.data.message ==
        "You have reached the limit of 7 uses for this module"
      ) {
        openModal();
      } else {
        toast.error(error.response.data.message);
      }
      setLoader2(false);
    }
  };
  const assessmentGererate = async () => {
    try {
      setLoader3(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/generate-lesson-objectives`,
        {
          lesson_title: lessonTitle,
          grade_level: showCustomGradeLevel ? customGradeLevel : gradeLevel,
          subject: showCustomSubject ? customSubject : subject,
          topic: topic,
          duration: duration,
          learning_objectives: objectives,
          activities: activities,
          assessment: "",
          differentiation: "",
          moduleType: "InteractiveLesson",
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      setLoader3(false);
      if (response.data.status) {
        const singleParagraph = response.data.data
          .replace(/^\{|\}$/g, "")
          .replace(/"/g, "");
        setAssessment(singleParagraph);
      }
    } catch (error) {
      setLoader3(false);
      if (
        error.response.data.message ==
        "You have reached the limit of 7 uses for this module"
      ) {
        openModal();
      } else {
        toast.error(error.response.data.message);
      }
    }
  };

  const differentiationGenrate = async () => {
    try {
      setLoader4(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/generate-lesson-objectives`,
        {
          lesson_title: lessonTitle,
          grade_level: showCustomGradeLevel ? customGradeLevel : gradeLevel,
          subject: showCustomSubject ? customSubject : subject,
          topic: topic,
          duration: duration,
          learning_objectives: objectives,
          activities: activities,
          assessment: assessment,
          differentiation: "",
          moduleType: "InteractiveLesson",
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      setLoader4(false);
      if (response.data.status) {
        console.log("response", response.data.data);
        const singleParagraph = response.data.data
          .replace(/^\{|\}$/g, "")
          .replace(/"/g, "");
        setDifferentiation(singleParagraph);
      }
    } catch (error) {
      if (
        error.response.data.message ==
        "You have reached the limit of 7 uses for this module"
      ) {
        openModal();
      } else {
        toast.error(error.response.data.message);
      }
      setLoader4(false);
    }
  };
  const handleGenerateWithAI = (field) => {
    switch (field) {
      case "objectives":
        objectGenerating();
        break;
      case "activities":
        activitiesGenerate();

        break;
      case "assessment":
        assessmentGererate();

        break;
      case "differentiation":
        differentiationGenrate();

        break;
      default:
        break;
    }
  };

  const downloadPdf = async () => {
    try {
      const response = await axios.get(review, {
        responseType: "blob",
      });

      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "lesson-planner.pdf");
      document.body.appendChild(link);
      link.click();

      link.remove();
      window.URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.error("Error downloading the PDF:", error.message);
    }
  };

  function previewFn() {
    const link = document.createElement("a");
    link.href = review;
    link.target = "_blank";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  const downloadFn = async () => {
    try {
      setIsGenerating(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/lesson-planner`,
        {
          lesson_title: lessonTitle,
          grade_level: showCustomGradeLevel ? customGradeLevel : gradeLevel,
          subject: showCustomSubject ? customSubject : subject,
          topic: topic,
          duration: duration,
          learning_objectives: objectives,
          activities: activities,
          assessment: assessment,
          differentiation: "",
          moduleType: "InteractiveLesson",
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      setLoader4(false);
      setIsGenerating(false);
      if (response.data.status) {
        // console.log("response", response.data.file_link);
        setReview(response.data.file_link);

        setGeneratedPlan({
          title: lessonTitle,
          gradeLevel: showCustomGradeLevel ? customGradeLevel : gradeLevel,
          subject: showCustomSubject ? customSubject : subject,
          topic,
          duration,
          objectives,
          activities,
          materials,
          assessment,
          differentiation,
          includeInteractiveElements,
          includeAccessibilityOptions,
          includeTechnologyIntegration,
        });
      }
    } catch (err) {
      setIsGenerating(false);
      if (
        err.response.data.message ==
        "You have reached the limit of 7 uses for this module"
      ) {
        openModal();
      } else {
        toast.error(error.response.data.message);
      }
    }
  };
  return (
    <div className="min-h-screen flex flex-col bg-blue-50">
      <ToastContainer position="top-center" />
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <Link
            to="/dashboard"
            className="flex items-center text-blue-600 hover:text-blue-800"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Dashboard
          </Link>
          <div className="flex items-center">
            <AfterLoginLogo />
          </div>
        </div>
      </header>

      <main className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">
          Interactive Lesson Planner
        </h1>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-white rounded-lg shadow-md p-6 mb-8"
            >
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                Lesson Details
              </h2>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label
                    htmlFor="lessonTitle"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Lesson Title
                  </label>
                  <Input
                    id="lessonTitle"
                    value={lessonTitle}
                    onChange={(e) => {
                      setLessonTitle(e.target.value);
                      setError1("");
                      setError("");
                    }}
                    placeholder="Enter lesson title"
                  />
                </div>

                <div>
                  <label
                    htmlFor="gradeLevel"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Grade Level
                  </label>
                  {!showCustomGradeLevel ? (
                    <Select value={gradeLevel} onValueChange={setGradeLevel}>
                      <SelectTrigger id="gradeLevel">
                        <SelectValue placeholder="Select grade level" />
                      </SelectTrigger>
                      <SelectContent>
                        {gradeLevels.map((level) => (
                          <SelectItem key={level} value={level}>
                            {level}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  ) : (
                    <Input
                      id="customGradeLevel"
                      value={customGradeLevel}
                      onChange={(e) => {
                        setCustomGradeLevel(e.target.value);
                        setError1("");
                        setError("");
                      }}
                      placeholder="Enter custom grade level"
                    />
                  )}
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() =>
                      setShowCustomGradeLevel(!showCustomGradeLevel)
                    }
                    className="mt-1"
                  >
                    {showCustomGradeLevel ? (
                      <>
                        <ChevronUp className="w-4 h-4 mr-2" />
                        Use predefined options
                      </>
                    ) : (
                      <>
                        <ChevronDown className="w-4 h-4 mr-2" />
                        Enter custom grade level
                      </>
                    )}
                  </Button>
                </div>

                <div>
                  <label
                    htmlFor="subject"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Subject
                  </label>
                  {!showCustomSubject ? (
                    <Select value={subject} onValueChange={setSubject}>
                      <SelectTrigger id="subject">
                        <SelectValue placeholder="Select subject" />
                      </SelectTrigger>
                      <SelectContent>
                        {subjects.map((s) => (
                          <SelectItem key={s} value={s}>
                            {s}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  ) : (
                    <Input
                      id="customSubject"
                      value={customSubject}
                      onChange={(e) => setCustomSubject(e.target.value)}
                      placeholder="Enter custom subject"
                    />
                  )}
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setShowCustomSubject(!showCustomSubject)}
                    className="mt-1"
                  >
                    {showCustomSubject ? (
                      <>
                        <ChevronUp className="w-4 h-4 mr-2" />
                        Use predefined options
                      </>
                    ) : (
                      <>
                        <ChevronDown className="w-4 h-4 mr-2" />
                        Enter custom subject
                      </>
                    )}
                  </Button>
                </div>

                <div>
                  <label
                    htmlFor="topic"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Topic
                  </label>
                  <Input
                    id="topic"
                    value={topic}
                    onChange={(e) => {
                      setTopic(e.target.value);
                      setError1("");
                      setError("");
                    }}
                    placeholder="Enter specific topic"
                  />
                </div>

                <div className="md:col-span-2">
                  <label
                    htmlFor="duration"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Duration (minutes)
                  </label>
                  <Slider
                    id="duration"
                    min={15}
                    max={120}
                    step={5}
                    value={[duration]}
                    onValueChange={(value) => setDuration(value[0])}
                  />
                  <div className="mt-2 text-sm text-gray-500">
                    {duration} minutes
                  </div>
                </div>
              </div>

              <h3 className="text-lg font-semibold text-gray-900 mt-6 mb-2">
                Learning Objectives
              </h3>
              {objectives.map((objective, index) => (
                <div key={index} className="flex items-center mb-2">
                  <Input
                    value={objective}
                    onChange={(e) =>
                      handleItemChange(
                        setObjectives,
                        objectives,
                        index,
                        e.target.value
                      )
                    }
                    placeholder={`Objective ${index + 1}`}
                    className="flex-grow"
                  />
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() =>
                      handleRemoveItem(setObjectives, objectives, index)
                    }
                    className="ml-2"
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              ))}
              <div className="text-red-600 flex justify-end">{error1}</div>
              <div className="flex justify-between items-center mt-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => handleAddItem(setObjectives, objectives)}
                >
                  <Plus className="h-4 w-4 mr-2" /> Add Objective
                </Button>
                <Button
                  disabled={
                    !lessonTitle ||
                    !topic ||
                    (showCustomGradeLevel ? !customGradeLevel : !gradeLevel) ||
                    (showCustomSubject ? !customSubject : !subject)
                  }
                  className={`flex items-center px-4 py-2 rounded-md ${
                    !lessonTitle ||
                    !topic ||
                    (showCustomGradeLevel ? !customGradeLevel : !gradeLevel) ||
                    (showCustomSubject ? !customSubject : !subject)
                      ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                      : "bg-blue-600 text-white hover:bg-blue-700"
                  } transition-colors duration-200`}
                  size="sm"
                  onClick={() => handleGenerateWithAI("objectives")}
                >
                  <Wand2 className="h-4 w-4 mr-2" />
                  {loader1 ? "Generating..." : "Generate with AI"}
                </Button>
              </div>

              <h3 className="text-lg font-semibold text-gray-900 mt-6 mb-2">
                Activities
              </h3>
              {activities.map((activity, index) => (
                <div key={index} className="flex items-center mb-2">
                  <Input
                    value={activity}
                    onChange={(e) => {
                      handleItemChange(
                        setActivities,
                        activities,
                        index,
                        e.target.value
                      );
                      setError("");
                    }}
                    placeholder={`Activity ${index + 1}`}
                    className="flex-grow"
                  />
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() =>
                      handleRemoveItem(setActivities, activities, index)
                    }
                    className="ml-2"
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              ))}
              <div className="flex justify-between items-center mt-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => handleAddItem(setActivities, activities)}
                >
                  <Plus className="h-4 w-4 mr-2" /> Add Activity
                </Button>
                <Button
                  disabled={
                    !lessonTitle ||
                    !topic ||
                    (showCustomGradeLevel ? !customGradeLevel : !gradeLevel) ||
                    (showCustomSubject ? !customSubject : !subject) ||
                    !objectives[0]
                  }
                  className={`flex items-center px-4 py-2 rounded-md ${
                    !lessonTitle ||
                    !topic ||
                    (showCustomGradeLevel ? !customGradeLevel : !gradeLevel) ||
                    (showCustomSubject ? !customSubject : !subject) ||
                    !objectives[0]
                      ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                      : "bg-blue-600 text-white hover:bg-blue-700"
                  } transition-colors duration-200`}
                  size="sm"
                  onClick={() => handleGenerateWithAI("activities")}
                >
                  <Wand2 className="h-4 w-4 mr-2" />{" "}
                  {loader2 ? "Generating..." : "Generate with AI"}
                </Button>
              </div>
              <div className="text-red-600 flex justify-end">
                {error.error2}
              </div>

              <h3 className="text-lg font-semibold text-gray-900 mt-6 mb-2">
                Materials
              </h3>
              {materials.map((material, index) => (
                <div key={index} className="flex items-center mb-2">
                  <Input
                    value={material}
                    onChange={(e) =>
                      handleItemChange(
                        setMaterials,
                        materials,
                        index,
                        e.target.value
                      )
                    }
                    placeholder={`Material ${index + 1}`}
                    className="flex-grow"
                  />
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() =>
                      handleRemoveItem(setMaterials, materials, index)
                    }
                    className="ml-2"
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              ))}
              <Button
                variant="outline"
                size="sm"
                onClick={() => handleAddItem(setMaterials, materials)}
                className="mt-2"
              >
                <Plus className="h-4 w-4 mr-2" /> Add Material
              </Button>

              <h3 className="text-lg font-semibold text-gray-900 mt-6 mb-2">
                Assessment
              </h3>
              <Textarea
                value={assessment}
                onChange={(e) => setAssessment(e.target.value)}
                placeholder="Describe how you will assess student learning"
                rows={4}
              />
              <div className="text-red-600 flex justify-end">
                {error.error3}
              </div>
              <div></div>
              <Button
                disabled={
                  !lessonTitle ||
                  !topic ||
                  (showCustomGradeLevel ? !customGradeLevel : !gradeLevel) ||
                  (showCustomSubject ? !customSubject : !subject) ||
                  !objectives[0] ||
                  !materials[0]
                }
                className={`flex items-center px-4 py-2 my-2 rounded-md ${
                  !lessonTitle ||
                  !topic ||
                  (showCustomGradeLevel ? !customGradeLevel : !gradeLevel) ||
                  (showCustomSubject ? !customSubject : !subject) ||
                  !objectives[0] ||
                  !materials[0]
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-blue-600 text-white hover:bg-blue-700"
                } transition-colors duration-200`}
                size="sm"
                onClick={() => handleGenerateWithAI("assessment")}
              >
                <Wand2 className="h-4 w-4 mr-2" />{" "}
                {loader3 ? "Generating..." : "Generate with AI"}
              </Button>

              <h3 className="text-lg font-semibold text-gray-900 mt-6 mb-2">
                Differentiation
              </h3>
              <Textarea
                value={differentiation}
                onChange={(e) => setDifferentiation(e.target.value)}
                placeholder="Describe strategies for differentiating instruction"
                rows={4}
              />
              <div className="text-red-600 flex justify-end">
                {error.error4}
              </div>
              <Button
                disabled={
                  !lessonTitle ||
                  !topic ||
                  (showCustomGradeLevel ? !customGradeLevel : !gradeLevel) ||
                  (showCustomSubject ? !customSubject : !subject) ||
                  !objectives[0] ||
                  !materials[0] ||
                  !assessment
                }
                className={`flex items-center px-4 py-2 my-2 rounded-md ${
                  !lessonTitle ||
                  !topic ||
                  (showCustomGradeLevel ? !customGradeLevel : !gradeLevel) ||
                  (showCustomSubject ? !customSubject : !subject) ||
                  !objectives[0] ||
                  !materials[0] ||
                  !assessment
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-blue-600 text-white hover:bg-blue-700"
                } transition-colors duration-200`}
                size="sm"
                onClick={() => handleGenerateWithAI("differentiation")}
              >
                <Wand2 className="h-4 w-4 mr-2" />{" "}
                {loader4 ? "Generating..." : "Generate with AI"}
              </Button>

              <div className="flex flex-col space-y-4 mt-6">
                <div className="flex items-center">
                  <Switch
                    id="interactive-elements"
                    checked={includeInteractiveElements}
                    onCheckedChange={setIncludeInteractiveElements}
                  />
                  <label
                    htmlFor="interactive-elements"
                    className="ml-2 text-sm text-gray-700"
                  >
                    Include interactive elements (e.g., quizzes, polls)
                  </label>
                </div>
                <div className="flex items-center">
                  <Switch
                    id="accessibility-options"
                    checked={includeAccessibilityOptions}
                    onCheckedChange={setIncludeAccessibilityOptions}
                  />
                  <label
                    htmlFor="accessibility-options"
                    className="ml-2 text-sm text-gray-700"
                  >
                    Include accessibility options
                  </label>
                </div>
                <div className="flex items-center">
                  <Switch
                    id="technology-integration"
                    checked={includeTechnologyIntegration}
                    onCheckedChange={setIncludeTechnologyIntegration}
                  />
                  <label
                    htmlFor="technology-integration"
                    className="ml-2 text-sm text-gray-700"
                  >
                    Include technology integration
                  </label>
                </div>
              </div>

              <div className="mt-8 flex justify-end">
                <Button
                  onClick={handleGenerate}
                  disabled={
                    isGenerating ||
                    !lessonTitle ||
                    !(gradeLevel || customGradeLevel) ||
                    !(subject || customSubject) ||
                    !topic
                  }
                >
                  {isGenerating ? (
                    <>
                      <Zap className="w-5 h-5 mr-2 animate-spin" />
                      Generating...
                    </>
                  ) : (
                    <>
                      <Book className="w-5 h-5 mr-2" />
                      Generate Lesson Plan
                    </>
                  )}
                </Button>
              </div>
            </motion.div>
          </div>

          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              Preview
            </h2>
            {generatedPlan ? (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="bg-white rounded-lg shadow-md p-6"
              >
                <h2 className="text-2xl font-semibold text-blue-600 mb-4">
                  {generatedPlan.title}
                </h2>
                <div className="grid grid-cols-1 gap-6">
                  <Card>
                    <CardHeader>
                      <CardTitle>Lesson Overview</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <p>
                        <strong>Grade Level:</strong> {generatedPlan.gradeLevel}
                      </p>
                      <p>
                        <strong>Subject:</strong> {generatedPlan.subject}
                      </p>
                      <p>
                        <strong>Topic:</strong> {generatedPlan.topic}
                      </p>
                      <p>
                        <strong>Duration:</strong> {generatedPlan.duration}{" "}
                        minutes
                      </p>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardHeader>
                      <CardTitle>Learning Objectives</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <ul className="list-disc pl-5">
                        {generatedPlan.objectives.map((objective, index) => (
                          <li key={index}>{objective}</li>
                        ))}
                      </ul>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardHeader>
                      <CardTitle>Activities</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <ol className="list-decimal pl-5">
                        {generatedPlan.activities.map((activity, index) => (
                          <li key={index}>{activity}</li>
                        ))}
                      </ol>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardHeader>
                      <CardTitle>Materials</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <ul className="list-disc pl-5">
                        {generatedPlan.materials.map((material, index) => (
                          <li key={index}>{material}</li>
                        ))}
                      </ul>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardHeader>
                      <CardTitle>Assessment</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <p>{generatedPlan.assessment}</p>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardHeader>
                      <CardTitle>Differentiation</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <p>{generatedPlan.differentiation}</p>
                    </CardContent>
                  </Card>
                </div>
                {(generatedPlan.includeInteractiveElements ||
                  generatedPlan.includeAccessibilityOptions ||
                  generatedPlan.includeTechnologyIntegration) && (
                  <Card className="mt-6">
                    <CardHeader>
                      <CardTitle>Additional Features</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <ul className="list-disc pl-5">
                        {generatedPlan.includeInteractiveElements && (
                          <li>
                            Interactive elements such as quizzes and polls will
                            be included in the lesson.
                          </li>
                        )}
                        {generatedPlan.includeAccessibilityOptions && (
                          <li>
                            Accessibility options will be provided to ensure
                            inclusive learning.
                          </li>
                        )}
                        {generatedPlan.includeTechnologyIntegration && (
                          <li>
                            Technology will be integrated into the lesson to
                            enhance learning outcomes.
                          </li>
                        )}
                      </ul>
                    </CardContent>
                  </Card>
                )}
                <div className="mt-8 flex justify-end space-x-4">
                  <Button variant="outline" onClick={previewFn}>
                    <Eye className="w-5 h-5 mr-2" />
                    Full Preview
                  </Button>
                  <Button variant="outline">
                    <Save className="w-5 h-5 mr-2" />
                    Save Draft
                  </Button>
                  <Button onClick={downloadPdf}>
                    <Download className="w-5 h-5 mr-2" />
                    Download
                  </Button>
                </div>
              </motion.div>
            ) : (
              <div className="bg-white rounded-lg shadow-md p-6 text-center text-gray-500">
                <Book className="w-16 h-16 mx-auto mb-4 text-blue-600" />
                <p>Generate a lesson plan to see the preview here.</p>
              </div>
            )}
          </div>
        </div>
      </main>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="max-w-lg bg-white rounded-lg p-6">
          {/* Modal Header */}
          <DialogHeader>
            <DialogTitle className="text-xl text-center font-semibold">
              You have reached the limit of 7 uses for this module
            </DialogTitle>
          </DialogHeader>

          {/* Modal Body */}
          <DialogDescription className="mb-4">
            {/* <p className="text-center text-lg ">Upgrade to our premium plan.</p> */}
            <div className="flex justify-center mt-3">
              <button
                onClick={() => {
                  setIsOpen(false);
                }}
                className="flex items-center outline-none px-4 py-2 mr-5 bg-white text-xl text-blue-600 rounded-md border border-blue-600 cursor-pointer hover:bg-blue-50 transition-colors duration-200 "
              >
                <Clock className="w-5 h-5 mr-2" /> Not Now!
              </button>
              <Link
                to="/payment"
                className="flex items-center bg-blue-600 hover:bg-blue-800 text-white text-xl px-4 py-2 rounded-lg   "
              >
                <CreditCard className="w-5 h-5 mr-2" /> Upgrade Now!
              </Link>
            </div>
          </DialogDescription>
        </DialogContent>
      </Dialog>
      <footer className="bg-blue-800 text-white py-8 mt-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row justify-between items-center">
          <p className="mb-4 md:mb-0">
            © {new Date().getFullYear()} ProfPilot. All rights reserved.
          </p>
          <div className="space-x-4">
            <a
              href="https://drive.google.com/file/d/1urzhyO5vzWwY8DAlaP6dPqWvlFlPxgKT/view"
              target="blank"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              Privacy Policy
            </a>
            <a
              href="https://drive.google.com/file/d/1xI82JEiNX0VnOwXx5vul2l2ZxhNTlNBE/view"
              target="blank"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              Terms of Service
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
