import React, { useState } from "react";
import { motion } from "framer-motion";
import { Input } from "../@/components/ui/input";
import { Button } from "../@/components/ui/button";
import AfterLoginLogo from "./DashLogo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ArrowLeft,
  FileText,
  Settings,
  Download,
  Eye,
  HelpCircle,
  ChevronDown,
  ChevronUp,
  CreditCard,
  XCircle,
  Clock,
} from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../@/components//ui/payment-dailog";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../@/components/ui/select";
import axios from "axios";
const subjects = [
  "Mathematics",
  "Science",
  "English",
  "History",
  "Geography",
  "Art",
];
const gradeLevels = [
  "Higher Education (College & University)",
  "High School (Grades 9 - 12)",
  "Middle School (Grades 7 - 8)",
  "Upper Elementary (Grades 4 - 6)",
  "Lower Elementary (Grades Pre-K - 3)",
];
const questionTypes = [
  "Multiple Choice",
  "Fill in the Blank",
  "Short Answer",
  "Essay",
  "Matching",
];

export default function WorksheetGenerator() {
  const [generationType, setGenerationType] = useState("worksheet");
  const [subject, setSubject] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [topic, setTopic] = useState("");
  const [numberOfQuestions, setNumberOfQuestions] = useState(10);
  const [selectedQuestionTypes, setSelectedQuestionTypes] = useState([]);
  const [difficultyLevel, setDifficultyLevel] = useState(3);
  const [isGenerating, setIsGenerating] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [generateRubric, setGenerateRubric] = useState(false);
  const [showCustomSubject, setShowCustomSubject] = useState(false);
  const [customSubject, setCustomSubject] = useState("");
  const [customGradeLevel, setCustomGradeLevel] = useState("");
  const [showCustomGradeLevel, setShowCustomGradeLevel] = useState(false);
  const [worksheetReponse, setWorkSheetReponse] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const handleQuestionTypeToggle = (type) => {
    setSelectedQuestionTypes((prev) =>
      prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type]
    );
  };

  const handleGenerate = async () => {
    setPreviewVisible(false);
    try {
      setIsGenerating(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/worksheet-generator`,
        {
          grade_level: showCustomGradeLevel ? customGradeLevel : gradeLevel,
          subject: showCustomSubject ? customSubject : subject,
          topic: topic,
          moduleType: "worksheet",
          number_of_questions: numberOfQuestions,
          difficulty_level:
            difficultyLevel < 3
              ? "esay"
              : difficultyLevel >= 3 && difficultyLevel < 5
              ? "medium"
              : "hard",
          question_type: selectedQuestionTypes,
          generate_rubric: generateRubric,
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      if (response.data.status) {
        setIsGenerating(false);
        setPreviewVisible(true);
        setWorkSheetReponse(response.data);
      }
    } catch (error) {
      if (
        error.response.data.message ==
        "You have reached the limit of 7 uses for this module"
      ) {
        openModal();
      } else {
        toast.error(error.response.data.message);
      }
      setIsGenerating(false);
    }
    // Simulating API call

    // setTimeout(() => {
    //   setIsGenerating(false);
    //   setPreviewVisible(true);
    // }, 2000);
  };
  const downloadPdf = async () => {
    try {
      const response = await axios.get(worksheetReponse.file_url, {
        responseType: "blob",
      });

      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "downloaded-file.pdf");
      document.body.appendChild(link);
      link.click();

      link.remove();
      window.URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.error("Error downloading the PDF:", error.message);
    }
  };
  const rubricGenerate = async () => {
    console.log("rubric", worksheetReponse.rubric_url);
    try {
      const response = await axios.get(worksheetReponse.rubric_url, {
        responseType: "blob",
      });

      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "rubric-file.pdf");
      document.body.appendChild(link);
      link.click();

      link.remove();
      window.URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.error("Error downloading the PDF:", error.message);
    }
  };
  function preview() {
    const link = document.createElement("a");
    link.href = worksheetReponse.file_url;
    link.target = "_blank";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  return (
    <div className="min-h-screen flex flex-col bg-blue-50">
      <ToastContainer position="top-center" />
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <Link
            to="/dashboard"
            className="flex items-center text-blue-600 hover:text-blue-800"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Dashboard
          </Link>
          <div className="flex items-center">
            <AfterLoginLogo />
          </div>
        </div>
      </header>

      <main className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">
          Worksheet Generator
        </h1>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-lg shadow-md p-6 mb-8"
        >
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold text-gray-900">
              Generate {generationType === "worksheet" ? "Worksheet" : "Quiz"}
            </h2>
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-600">Worksheet</span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={generationType === "quiz"}
                  onChange={() =>
                    setGenerationType((prev) =>
                      prev === "worksheet" ? "quiz" : "worksheet"
                    )
                  }
                />
                <span className="slider round"></span>
              </label>
              <span className="text-sm text-gray-600">Quiz</span>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="subject"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Subject
              </label>
              {!showCustomSubject ? (
                <Select value={subject} onValueChange={setSubject}>
                  <SelectTrigger id="subject">
                    <SelectValue placeholder="Select subject" />
                  </SelectTrigger>
                  <SelectContent>
                    {subjects.map((s) => (
                      <SelectItem key={s} value={s}>
                        {s}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              ) : (
                <Input
                  id="customSubject"
                  value={customSubject}
                  onChange={(e) => setCustomSubject(e.target.value)}
                  placeholder="Enter custom subject"
                />
              )}
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setShowCustomSubject(!showCustomSubject)}
                className="mt-1"
              >
                {showCustomSubject ? (
                  <>
                    <ChevronUp className="w-4 h-4 mr-2" />
                    Use predefined options
                  </>
                ) : (
                  <>
                    <ChevronDown className="w-4 h-4 mr-2" />
                    Enter custom subject
                  </>
                )}
              </Button>
              {/* <select
                id="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select subject</option>
                {subjects.map((s) => (
                  <option key={s} value={s}>
                    {s}
                  </option>
                ))}
              </select> */}
            </div>

            <div>
              <label
                htmlFor="gradeLevel"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Grade Level
              </label>
              {!showCustomGradeLevel ? (
                <Select value={gradeLevel} onValueChange={setGradeLevel}>
                  <SelectTrigger id="gradeLevel">
                    <SelectValue placeholder="Select grade level" />
                  </SelectTrigger>
                  <SelectContent>
                    {gradeLevels.map((level) => (
                      <SelectItem key={level} value={level}>
                        {level}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              ) : (
                <Input
                  id="customGradeLevel"
                  value={customGradeLevel}
                  onChange={(e) => setCustomGradeLevel(e.target.value)}
                  placeholder="Enter custom grade level"
                />
              )}
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setShowCustomGradeLevel(!showCustomGradeLevel)}
                className="mt-1"
              >
                {showCustomGradeLevel ? (
                  <>
                    <ChevronUp className="w-4 h-4 mr-2" />
                    Use predefined options
                  </>
                ) : (
                  <>
                    <ChevronDown className="w-4 h-4 mr-2" />
                    Enter custom grade level
                  </>
                )}
              </Button>
            </div>

            <div className="md:col-span-2">
              <label
                htmlFor="topic"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Topic
              </label>
              <input
                type="text"
                id="topic"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
                placeholder="Enter specific topic"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div>
              <label
                htmlFor="numberOfQuestions"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Number of Questions
              </label>
              <input
                type="number"
                id="numberOfQuestions"
                value={numberOfQuestions}
                onChange={(e) => setNumberOfQuestions(parseInt(e.target.value))}
                min="1"
                max="50"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Difficulty Level
              </label>
              <input
                type="range"
                min="1"
                max="5"
                value={difficultyLevel}
                onChange={(e) => {
                  setDifficultyLevel(parseInt(e.target.value));
                  console.log(parseInt(e.target.value));
                }}
                className="w-full"
              />
              <div className="flex justify-between text-xs text-gray-500">
                <span>Easy</span>
                <span>Medium</span>
                <span>Hard</span>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Question Types
            </label>
            <div className="flex flex-wrap gap-2">
              <button
                // key={type}
                onClick={() => handleQuestionTypeToggle("Multiple Choice")}
                className={`px-3 py-1 rounded-full text-sm ${
                  selectedQuestionTypes.includes("Multiple Choice")
                    ? "bg-blue-600 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
              >
                Multiple Choice
              </button>
              {generationType == "worksheet" && (
                <>
                  <button
                    // key={type}
                    onClick={() =>
                      handleQuestionTypeToggle("Fill in the Blank")
                    }
                    className={`px-3 py-1 rounded-full text-sm ${
                      selectedQuestionTypes.includes("Fill in the Blank")
                        ? "bg-blue-600 text-white"
                        : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                    }`}
                  >
                    Fill in the Blank
                  </button>
                  <button
                    // key={type}
                    onClick={() => handleQuestionTypeToggle("Short Answer")}
                    className={`px-3 py-1 rounded-full text-sm ${
                      selectedQuestionTypes.includes("Short Answer")
                        ? "bg-blue-600 text-white"
                        : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                    }`}
                  >
                    Short Answer
                  </button>
                  <button
                    // key={type}
                    onClick={() => handleQuestionTypeToggle("Essay")}
                    className={`px-3 py-1 rounded-full text-sm ${
                      selectedQuestionTypes.includes("Essaye")
                        ? "bg-blue-600 text-white"
                        : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                    }`}
                  >
                    Essay
                  </button>
                  <button
                    // key={type}
                    onClick={() => handleQuestionTypeToggle("Matching")}
                    className={`px-3 py-1 rounded-full text-sm ${
                      selectedQuestionTypes.includes("Matching")
                        ? "bg-blue-600 text-white"
                        : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                    }`}
                  >
                    Matching
                  </button>
                </>
              )}
            </div>
          </div>

          <div className="mt-6 flex items-center">
            <input
              type="checkbox"
              id="generateRubric"
              checked={generateRubric}
              onChange={(e) => setGenerateRubric(e.target.checked)}
              className="mr-2"
            />
            <label htmlFor="generateRubric" className="text-sm text-gray-700">
              Generate Rubric
            </label>
            <HelpCircle
              className="w-4 h-4 ml-2 text-gray-400 cursor-help"
              title="A rubric will be generated to help with grading"
            />
          </div>

          <div className="mt-8 flex justify-end">
            <button
              onClick={handleGenerate}
              disabled={
                isGenerating ||
                (showCustomGradeLevel ? !customGradeLevel : !gradeLevel) ||
                (showCustomSubject ? !customSubject : !subject) ||
                !topic ||
                selectedQuestionTypes.length === 0
              }
              className={`flex items-center px-4 py-2 rounded-md ${
                isGenerating ||
                (showCustomGradeLevel ? !customGradeLevel : !gradeLevel) ||
                (showCustomSubject ? !customSubject : !subject) ||
                !topic ||
                selectedQuestionTypes.length === 0
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-blue-600 text-white hover:bg-blue-700"
              } transition-colors duration-200`}
            >
              {isGenerating ? (
                <>
                  <Settings className="w-5 h-5 mr-2 animate-spin" />
                  Generating...
                </>
              ) : (
                <>
                  <FileText className="w-5 h-5 mr-2" />
                  Generate{" "}
                  {generationType === "worksheet" ? "Worksheet" : "Quiz"}
                </>
              )}
            </button>
          </div>
        </motion.div>

        {previewVisible && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-white rounded-lg shadow-md p-6"
          >
            <h2 className="text-xl font-semibold text-gray-900 mb-4">
              {generationType === "worksheet" ? "Worksheet" : "Quiz"} Preview
            </h2>
            <div className="bg-gray-100 p-4 rounded-md mb-4  flex items-center justify-center custom_iframe_height">
              <iframe
                src={worksheetReponse.file_url}
                style={{ width: "100%", height: "100%" }}
                frameborder="0"
              ></iframe>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={preview}
                className="flex items-center px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors duration-200"
              >
                <Eye className="w-5 h-5 mr-2" />
                Full Preview
              </button>
              <button
                onClick={downloadPdf}
                className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
              >
                <Download className="w-5 h-5 mr-2" />
                Download
              </button>
            </div>
            {generateRubric && worksheetReponse.rubric_url && (
              <div className="mt-6 h-full">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  Rubric
                </h3>
                <div className="bg-gray-100 p-4 rounded-md mb-4 h-32 flex items-center justify-center custom_iframe_height">
                  {/* <p className="text-gray-500">
                    Rubric preview would be displayed here
                  </p> */}
                  <iframe
                    src={worksheetReponse.rubric_url}
                    style={{ width: "100%", height: "100%" }}
                    frameborder="0"
                  ></iframe>
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={rubricGenerate}
                    className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
                  >
                    <Download className="w-5 h-5 mr-2" />
                    Download Rubric
                  </button>
                </div>
              </div>
            )}
          </motion.div>
        )}
      </main>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="max-w-lg bg-white rounded-lg p-6">
          {/* Modal Header */}
          <DialogHeader>
            <DialogTitle className="text-xl text-center font-semibold">
              You have reached the limit of 7 uses for this module
            </DialogTitle>
          </DialogHeader>

          {/* Modal Body */}
          <DialogDescription className="mb-4">
            {/* <p className="text-center text-lg ">Upgrade to our premium plan.</p> */}
            <div className="flex justify-center mt-3">
              <button
                onClick={() => {
                  setIsOpen(false);
                }}
                className="flex items-center outline-none px-4 py-2 mr-5 bg-white text-xl text-blue-600 rounded-md border border-blue-600 cursor-pointer hover:bg-blue-50 transition-colors duration-200 "
              >
                <Clock className="w-5 h-5 mr-2" /> Not Now!
              </button>
              <Link
                to="/payment"
                className="flex items-center bg-blue-600 hover:bg-blue-800 text-white text-xl px-4 py-2 rounded-lg   "
              >
                <CreditCard className="w-5 h-5 mr-2" /> Upgrade Now!
              </Link>
            </div>
          </DialogDescription>
        </DialogContent>
      </Dialog>
      <footer className="bg-blue-800 text-white py-8 mt-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row justify-between items-center">
          <p className="mb-4 md:mb-0">
            © {new Date().getFullYear()} ProfPilot. All rights reserved.
          </p>
          <div className="space-x-4">
            <a
              href="https://drive.google.com/file/d/1urzhyO5vzWwY8DAlaP6dPqWvlFlPxgKT/view"
              target="blank"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              Privacy Policy
            </a>
            <a
              href="https://drive.google.com/file/d/1xI82JEiNX0VnOwXx5vul2l2ZxhNTlNBE/view"
              target="blank"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              Terms of Service
            </a>
          </div>
        </div>
      </footer>

      <style jsx>{`
        .switch {
          position: relative;
          display: inline-block;
          width: 60px;
          height: 34px;
        }

        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          transition: 0.4s;
        }

        .slider:before {
          position: absolute;
          content: "";
          height: 26px;
          width: 26px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          transition: 0.4s;
        }

        input:checked + .slider {
          background-color: #3b82f6;
        }

        input:checked + .slider:before {
          transform: translateX(26px);
        }

        .slider.round {
          border-radius: 34px;
        }

        .slider.round:before {
          border-radius: 50%;
        }
      `}</style>
    </div>
  );
}
