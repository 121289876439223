import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion } from "framer-motion";
import { Eye, EyeOff, User, GraduationCap } from "lucide-react";

import { Link } from "react-router-dom";
import AfterLoginLogo from "./AfterLoginLogo";
import google from "../assets/googlelogo.svg";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../firebase";
import { LoaderCircle } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
export default function LoginScreen({
  setSignUpData,
  signUpData,
  userType,
  setUserType,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [classCode, setClassCode] = useState("");
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [signError, setSignError] = useState("");
  const [signLoader, setSignLoader] = useState(false);
  const [logUserType, setLogUserType] = useState("teacher");
  const [loginData, setLoginData] = useState({
    loginEmail: "",
    loginPassword: "",
  });

  const { code } = useParams();
  useEffect(() => {
    setUserType("teacher");
    console.log("useType", userType);
    setClassCode(code);
  }, []);

  const validatePassword = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let isValid = false;
    if (signUpData.SignUppassword && signUpData.confirmPassword) {
      if (signUpData.SignUppassword !== signUpData.confirmPassword) {
        isValid = false;
        toast.error("Passwords does not match!");
      } else {
        isValid = true;
      }
    } else {
      isValid = false;
      toast.error("All fields are required!");
    }
    // if (emailRegex.test(loginData.loginEmail)) {
    //   isValid = true;
    // } else {
    //   isValid = false;
    //   toast.error("Invalid email address. Please enter a valid one.");
    // }
    return isValid;
  };

  const loginFn = async (firebaseToken, email) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/login-user`,
        {
          email: email,
          tokenid: firebaseToken,
          profession_type: userType,
          class_code: classCode,
        }
      );
      if (response.data.status) {
        console.log("response", response.data.payment_status);
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("payment-status", response.data.payment_status);
        localStorage.setItem("user-type", userType);
        if (userType == "student") {
          localStorage.setItem("class_code", classCode);
          window.location.href = "/";
        } else {
          if (response.data.payment_status) {
            console.log("handling the condition");

            window.location.href = "/";
          } else {
            console.log("not handle");
            window.location.href = "/payment";
          }
        }
      }
      setLoader(false);
      setSignLoader(false);
    } catch (err) {
      console.log("error messagedhfjd", err);

      setLoader(false);
      setSignLoader(false);
      // setSignError(err.response.data.message);
      toast.error(err.response.data.message);
    }
    setLoader(false);
    setSignLoader(false);
  };

  const signInFn = (e) => {
    setSignError("");
    const { name, value } = e.target;
    setLoginData((signInData) => ({ ...signInData, [name]: value }));
  };
  const signUpfn = (e) => {
    setError("");
    const { name, value } = e.target;
    console.log(value);

    setSignUpData((signUpData) => ({ ...signUpData, [name]: value }));
  };

  const signValidatePassword = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    let isValid = true;
    if (loginData.loginEmail == "" && loginData.loginPassword == "") {
      isValid = false;
      toast.error("All fields are required");
      // setSignError("All fields are required");
      setSignLoader(false);
    }
    // if (emailRegex.test(loginData.loginEmail)) {
    //   isValid = true;
    // } else {
    //   isValid = false;
    //   toast.error(
    //     "Invalid EmaInvalid email address. Please enter a valid one."
    //   );
    // }
    return isValid;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setSignError("");
    if (signValidatePassword()) {
      setSignLoader(true);
      signInWithEmailAndPassword(
        auth,
        loginData.loginEmail,
        loginData.loginPassword
      )
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user.accessToken;
          loginFn(user, loginData.loginEmail);
        })
        .catch((error) => {
          // if (error.message == "Firebase: Error (auth/invalid-credential)")
          console.log("hdnjfdnjfdkfmdkf", error.message);
          if (error.message == "Firebase: Error (auth/invalid-credential).") {
            toast.error("Invalid login credentials");
          } else if (
            error.message ==
            "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests)."
          ) {
            toast.error("Too many attempts try again later ");
          } else {
            toast.error(error.message);
          }
          // setSignError(error.message);
          setSignLoader(false);
        });
    }
  };

  const handleSignup = (e) => {
    e.preventDefault();
    setError("");
    if (validatePassword()) {
      setLoader(true);
      // Create a new user with email and password using firebase
      createUserWithEmailAndPassword(
        auth,
        signUpData.signUpemail,
        signUpData.SignUppassword
      )
        .then((res) => {
          localStorage.setItem("user-token", res.user.accessToken);
          localStorage.setItem("user-type", userType);

          if (userType == "teacher") {
            navigate("/user-personal");
          } else if (userType == "student") {
            navigate("/student-personal");

            // loginFn(res.user.accessToken, signUpData.signUpemail);
          }
        })
        .catch((err) => {
          if (
            err.message ==
            "Firebase: Password should be at least 6 characters (auth/weak-password)."
          ) {
            toast.error("Password should be at least 6 characters");
          } else if (
            err.message == "Firebase: Error (auth/email-already-in-use)."
          ) {
            toast.error("An account with this email already exists");
          } else {
            toast.error(err.message);
          }
          setLoader(false);
        });
    }
  };

  const loginWithGoogle = async () => {
    let provider = new GoogleAuthProvider();
    provider.setCustomParameters();
    const result = await signInWithPopup(auth, provider);

    console.log("signinwithgoogle", result);

    const user_token = result.user.accessToken;
    const user_email = result.user.email;
    await loginFn(user_token, user_email);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-600 to-blue-800 flex flex-col items-center justify-center p-4">
      <ToastContainer position="top-center" />
      <motion.div
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <div className="flex justify-center mb-8">
          <AfterLoginLogo />
        </div>
        <div className="bg-white rounded-xl shadow-xl overflow-hidden">
          <div className="p-8">
            <h2 className="text-3xl font-bold text-center text-blue-600 mb-8">
              {!isLoggedIn ? "Login" : "Sign Up"} to ProfPilot
            </h2>
            <div className="flex justify-center space-x-4 mb-6">
              <button
                onClick={() => {
                  setLogUserType("teacher");
                  setUserType("teacher");
                  setSignError("");
                  setLoginData({ loginEmail: "", loginPassword: "" });
                }}
                className={`flex items-center px-4 py-2 rounded-md ${
                  logUserType === "teacher"
                    ? "bg-blue-600 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
              >
                <User className="w-5 h-5 mr-2" />
                Teacher
              </button>
              <button
                onClick={() => {
                  setUserType("student");
                  setLogUserType("student");
                  setSignError("");
                  setLoginData({ loginEmail: "", loginPassword: "" });
                }}
                className={`flex items-center px-4 py-2 rounded-md ${
                  logUserType === "student"
                    ? "bg-blue-600 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
              >
                <GraduationCap className="w-5 h-5 mr-2" />
                Student
              </button>
            </div>
            {!isLoggedIn ? (
              <>
                <form className="space-y-6" onSubmit={handleLogin}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Email
                    </label>
                    <input
                      id="email"
                      type="text"
                      autoComplete="email"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Enter your email"
                      name="loginEmail"
                      value={loginData.loginEmail}
                      onChange={signInFn}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Password
                    </label>
                    <div className="relative">
                      <input
                        id="password"
                        type={showPassword ? "text" : "password"}
                        autoComplete="current-password"
                        name="loginPassword"
                        value={loginData.loginPassword}
                        onChange={signInFn}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Enter your password"
                      />
                      <button
                        type="button"
                        className="absolute inset-y-0 right-0 pr-3 flex items-center"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <EyeOff className="h-5 w-5 text-gray-400" />
                        ) : (
                          <Eye className="h-5 w-5 text-gray-400" />
                        )}
                      </button>
                    </div>
                  </div>
                  {userType === "student" && (
                    <div>
                      <label
                        htmlFor="classCode"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Class Code
                      </label>
                      <input
                        id="classCode"
                        name="classCode"
                        type="text"
                        value={classCode}
                        onChange={(e) => setClassCode(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Enter your class code"
                      />
                    </div>
                  )}
                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                      disabled={signLoader}
                    >
                      {!signLoader ? (
                        "Log In"
                      ) : (
                        <div className="spinner-container">
                          <LoaderCircle className="spinner" size={20} />
                        </div>
                      )}
                    </button>
                  </div>
                </form>
                <div className="text-xl text-red-500 font-medium mt-2">
                  {signError}
                </div>

                <div className="mt-6">
                  <button
                    type="button"
                    className="w-full flex justify-center items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                  >
                    <img
                      src={google}
                      alt="Google logo"
                      width={20}
                      height={20}
                      className="mr-2"
                    />
                    Log In with Google
                  </button>
                </div>

                <p className="mt-6 text-center text-sm text-gray-600">
                  Don't have an account?{" "}
                  <span
                    onClick={() => {
                      setIsLoggedIn(true);
                    }}
                    className="font-medium text-blue-600 hover:text-blue-500 transition-colors duration-200 cursor-pointer"
                  >
                    Sign Up
                  </span>
                </p>
              </>
            ) : (
              <>
                <form className="space-y-6" onSubmit={handleSignup}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Email
                    </label>
                    <input
                      id="email"
                      type="email"
                      autoComplete="email"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Enter your email"
                      value={signUpData.signUpemail}
                      name="signUpemail"
                      onChange={signUpfn}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Password
                    </label>
                    <div className="relative">
                      <input
                        id="password"
                        type={showPassword ? "text" : "password"}
                        value={signUpData.SignUppassword}
                        name="SignUppassword"
                        onChange={signUpfn}
                        autoComplete="current-password"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Enter your password"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="confirm_password"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Confirm Password
                    </label>
                    <div className="relative">
                      <input
                        id="confirm_password"
                        type={showPassword ? "text" : "password"}
                        value={signUpData.confirmPassword}
                        name="confirmPassword"
                        onChange={signUpfn}
                        autoComplete="current-password"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Confirm your password"
                      />
                      <button
                        type="button"
                        className="absolute inset-y-0 right-0 pr-3 flex items-center"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <EyeOff className="h-5 w-5 text-gray-400" />
                        ) : (
                          <Eye className="h-5 w-5 text-gray-400" />
                        )}
                      </button>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                      disabled={signLoader}
                    >
                      {loader ? (
                        <div className="spinner-container">
                          <LoaderCircle className="spinner" size={20} />
                        </div>
                      ) : (
                        "Sign Up"
                      )}
                    </button>
                  </div>
                </form>

                <p className="mt-6 text-center text-sm text-gray-600">
                  Already have an account?{" "}
                  <span
                    onClick={() => {
                      setIsLoggedIn(false);
                    }}
                    className=" font-medium text-blue-600 hover:text-blue-500 transition-colors duration-200 cursor-pointer"
                  >
                    Sign In
                  </span>
                </p>
              </>
            )}
          </div>
        </div>
      </motion.div>
      <p className="mt-8 text-center text-sm text-blue-200">
        © {new Date().getFullYear()} ProfPilot. All rights reserved.
      </p>
      <p className="mt-2 text-center text-sm text-blue-200">
        <Link
          href="/privacy-policy"
          className="hover:text-blue-100 transition-colors duration-200"
        >
          Privacy Policy
        </Link>
      </p>
    </div>
  );
}
